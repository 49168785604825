import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import { TypeMigrationPageQueryResult } from "../../utils/queries/migrations";
import AmazeePage from "../../components/layout";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import { AmazeeIOButton } from "../../components/button/button";
import { BlogPostEventType, CaseStudyDocument, ResourceType } from "../../utils/queries/migrations";

import './index.scss'


const EnterPriseHostingMigration = ({ location }) => {
  const { allPrismicMigrations } = useStaticQuery<TypeMigrationPageQueryResult>(query);
  const result = allPrismicMigrations.edges[0].node.data;

  return <AmazeePage classSet={''} location={location} id={'enterprise-hosting-migration'}>
    <AmazeeIoMetadata
      title={"Enterprise Hosting Migration | amazee.io"}
      page_meta_description={result.page_meta_description}
      page_meta_thumbnail={result.page_meta_thumbnail}
      location={location}
    />
    <main id="page-migration">
      {/* HERO SECTION */}
      <section className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto pb-10 md:py-16 px-6">
        <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
          <div className="">
            <h1 className="text-4xl hidden lg:block md:text-5xl xl:hidden md:leading-amazee-60 font-semibold text-black">{result.hero_heading}</h1>
          </div>
          <div className="flex flex-col-reverse lg:grid lg:grid-cols-2 gap-8">
            <div>
              <h1 className="xl:block lg:hidden text-4xl md:text-5xl leading-amazee-46 md:leading-amazee-60 font-semibold text-black">{result.hero_heading}</h1>
              <p className="text-lg mt-4 text-black font-light">{result.hero_subtitle}</p>
            </div>
            <div className="flex items-center">
              <img src={result.hero_image_right.url} alt={result.hero_image_right.alt} className="w-full" />
            </div>
          </div>
          <div className="flex flex-col lg:grid lg:grid-cols-2 mt-4 gap-8">
            <div className="flex items-center">
              <img src={result.hero_image_left.url} alt={result.hero_image_left.alt} className="w-full" />
            </div>
            <div className="mt-4">
              <h3 className="text-3xl font-medium text-black">{result.hero_content_right_title}</h3>
              <div className="mt-4 hero-list text-lg text-black font-light">
                {RichTextRender(result.hero_body_content_right)}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ENTERPRISE SITE MIGRATION */}
      <section className="grid-bg-elipse py-10 md:py-16">
        <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto px-6 ">
          <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
            <div>
              <h4 className="text-3.5xl mx-auto font-semibold leading-amazee-46 text-center text-black">{result.enterprise_site_heading}</h4>
              <p className="mx-auto lg:w-3/5 text-lg text-center mt-4 text-black font-bold">{result.enterprise_site_sub_heading}</p>
              <div className="mx-auto xl:w-4/5 2xl:w-am-70 text-lg text-black text-center font-light">{RichTextRender(result.enterprise_site_content)}</div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-12">
                {result.enterprise_site_icon_collection.map((item, index) => (<div key={index} className="flex flex-col gap-3">
                  <img src={item?.icon.url} alt={item.icon.alt} loading="lazy" className="w-20 h-20 mx-auto" />
                  <p className="text-2xl font-semibold text-black text-center mx-auto mb-0">{item.heading}</p>
                  <p className="text-lg text-black w-full 2xl:w-4/5 mx-auto mb-0 font-light text-center">{item.content}</p>
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* WEBSITE HOSTING */}
      <section className="py-10 md:py-16 max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto px-6 ">
        <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
          <div className="lg:grid lg:grid-cols-2 gap-4 flex flex-col-reverse">
            <div>
              <h4 className="text-3xl mx-auto font-semibold leading-amazee-46 text-black">{result.cta_card_heading}</h4>
              <div className="mt-4 lg:w-11/12 text-lg hosting text-black font-light">{RichTextRender(result.cta_card_content)}</div>
              <a href={result.cta_card_read_more_url}>
                <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                  {result.cta_card_read_more}
                </AmazeeIOButton>
              </a>
            </div>
            <div className="flex items-center">
              <img src={result.cta_card_image.url} alt={result.cta_card_image.alt} className="w-full" />
            </div>
          </div>
        </div>
      </section>

      {/* MULTISITE MIGRATIONS */}
      <section className="grid-bg-elipse py-10 md:py-16">
        <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto px-6 ">
          <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
            <div>
              <h4 className="text-3.5xl mx-auto font-semibold leading-amazee-46 text-center text-black">{result.mutlisite_migration_heading}</h4>
              <p className="mx-auto lg:w-3/5 text-lg text-center mt-4 text-black font-bold">{result.mutlisite_migration_subtitle}</p>
              <div className="mx-auto xl:w-4/5 2xl:w-am-70 text-lg text-black text-center font-light">{RichTextRender(result.mutlisite_migration_content)}</div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-12">
                {result.mutlisite_migration_items_collection.map((item, index) => (<div key={index} className="flex flex-col gap-3">
                  <img src={item?.image.url} alt={item.image.alt} loading="lazy" className="w-20 h-20 mx-auto" />
                  <p className="text-2xl font-semibold text-black text-center mx-auto mb-0">{item.heading}</p>
                  <p className="text-lg text-black w-full 2xl:w-4/5 mx-auto mb-0 font-light text-center">{item.content}</p>
                </div>))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA MAIN */}
      <section className="cta-main py-10 md:py-16">
        <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto px-6 ">
            <div className="text-white text-2xl md:text-3xl lg:text-3.5xl font-medium w-am-83 text-center mx-auto">{RichTextRender(result.cta_main_content)}</div>
            <div className="text-center">
              <a href={'/get-in-touch'}>
                <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                  {result.cta_main_button}
                </AmazeeIOButton>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* RELATED RESOURCES */}
      <section className="py-10 md:py-16 bg-white">
        <div className="xl:max-w-screen-amazee-83 mx-auto xl:px-2">
          <div className="max-w-screen-amazee-lg xl:max-w-screen-amazee-xl 2xl:max-w-screen-2xl mx-auto px-6 lg:px-0">
            <p className="text-center text-3.5xl font-medium text-black">
              {result.stories_title}
            </p>

            <div className={`grid md:grid-cols-2 ${result.customer_stories.length <= 2 ? "lg:grid-cols-8 xl:grid-cols-12" : "lg:grid-cols-3"} gap-8`}>
              {result.customer_stories.map((story, index) => {
                const type = story.case_study.document.type;
                const caseStudy = story.case_study.document.data;
                return type === 'blog_post' || type === 'event' ?
                  <BlogEventComponent key={index} data={caseStudy} customer_stories_length={result.customer_stories.length} index={index} type={type} /> :
                  <ResourceComponent key={index} data={caseStudy} customer_stories_length={result.customer_stories.length} index={index} />
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  </AmazeePage>
}

export const BlogEventComponent: React.FC<{
  data: BlogPostEventType,
  customer_stories_length: number,
  index: number,
  type: CaseStudyDocument['type']
}>
  = ({ data, customer_stories_length, index, type }) => {
    return (
      <div className={`related-cards flex flex-col bg-amazee-solitude rounded-xl 
    hover:shadow-2xl cursor-pointer no-underline ${customer_stories_length <= 2 ? `lg:col-span-4 ${index === 0 ? 'xl:col-start-3' : ''}` : ''} `}>
        <img src={data.main_image.url} alt={data.main_image.alt} className="w-full rounded-t-xl" />
        <div className="p-amazee-30 flex flex-col justify-between h-full">
          <div className="no-underline">
            <h4 className="text-2xl font-medium mb-4 text-black">{data.title}</h4>
            <p className="text-lg font-light text-black">{data.summary}</p>
          </div>
          <div className="">
            <a href={`${type === 'event' ? `/events/${data.slug}` : `/${data.slug}`}`}
              className="text-amazee-deep-sky-blue read-more-link hover:no-underline hover:text-amazee-deep-sky-blue ml-0 mb-0 w-full inline-block">{`Read more`}</a>
          </div>
        </div>
      </div>
    )
  }

export const ResourceComponent: React.FC<{
  data: ResourceType,
  customer_stories_length: number,
  index: number
}>
  = ({ data, customer_stories_length, index }) => {
    return (
      <div className={`related-cards flex flex-col bg-amazee-solitude rounded-xl 
    hover:shadow-2xl cursor-pointer no-underline ${customer_stories_length <= 2 ? `lg:col-span-4 ${index === 0 ? 'xl:col-start-3' : ''}` : ''} `}>
        <img src={data.main_image.url} alt={data.main_image.alt} className="w-full rounded-t-xl" />
        <div className="p-amazee-30 flex flex-col justify-between h-full">
          <div className="no-underline">
            <h4 className="text-2xl font-medium mb-4 text-black">{data.title}</h4>
            <p className="text-lg font-light text-black">{data.description.text}</p>
          </div>
          <div className="">
            <a href={data.link.url}
              className="text-amazee-deep-sky-blue read-more-link hover:no-underline hover:text-amazee-deep-sky-blue ml-0 mb-0 w-full inline-block">{`Read more`}</a>
          </div>
        </div>
      </div>
    )
  }

const query = graphql`
query {
  allPrismicMigrations {
    edges {
      node {
        data {
          stories_title
          customer_stories {
            case_study {
              document {
                ... on PrismicBlogPost {
                  id
                  type
                  data {
                    title
                    main_image {
                      alt
                      url
                    }
                    slug
                    summary
                  }
                }
                ... on PrismicEvent {
                  id
                  type
                  data {
                    title
                    slug: urlslug
                    summary: page_meta_description
                    main_image: page_meta_thumbnail {
                      url
                      alt
                    }
                  }
                }
                ... on PrismicResource {
                  id
                  type
                  data {
                    main_image: image {
                      url
                      alt
                    }
                    title
                    description {
                      html
                      raw
                      text
                    }
                    link {
                      url
                    }
                  }
                }
              }
            }
          }
          cta_card_heading
          cta_card_content {
            raw
            html
            text
          }
          cta_card_image {
            alt
            url
          }
          cta_card_read_more
          cta_card_read_more_url
          cta_main_content {
            html
            raw
            text
          }
          cta_main_button
          enterprise_site_content {
            html
            raw
            text
          }
          enterprise_site_heading
          enterprise_site_icon_collection {
            content
            heading
            icon {
              alt
              url
            }
          }
          enterprise_site_sub_heading
          hero_body_content_right {
            html
            raw
            text
          }
          hero_content_right_title
          hero_image {
            alt
            url
          }
          hero_heading
          hero_image_left {
            alt
            url
          }
          hero_image_right {
            alt
            url
          }
          meta_title
          hero_subtitle
          mutlisite_migration_content {
            text
            raw
            html
          }
          mutlisite_migration_heading
          mutlisite_migration_items_collection {
            content
            heading
            image {
              alt
              url
            }
          }
          mutlisite_migration_subtitle
          page_meta_description
          page_meta_thumbnail {
            alt
            url
          }
          subtitle
          title
        }
      }
    }
  }
}
`

export default EnterPriseHostingMigration;